import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "../CarModel.module.css";
import CarModelMap from "../../map/CarModelMap";
import desImg from "../../../images/Group 17326.png";
import maskGroup from "../../../images/Mask Group 2.png";
import CenterName from "./centerName/CenterName";

function Location({ location, pickup, delivery, locationCenterName }) {
    const [disData, setDisData] = useState("");

    return (
        <>
            <CenterName locationCenterName={locationCenterName} />
            {pickup.name && delivery.name && (
                <div className={styles.location}>
                    <>
                        {location.map((el, index) => (
                            <div className={styles.left} key={index}>
                                {pickup.name && delivery.name && (
                                    <>
                                        <div className={styles.toFrom}>
                                            <div className={styles.to}>
                                                <div>
                                                    <img src={maskGroup} />
                                                </div>
                                                <div>
                                                    <p title={el.delivery.name}>{el.delivery.name}</p>
                                                    <span title={el.delivery.address}>{el.delivery.address}</span>
                                                </div>
                                            </div>
                                            <div className={styles.from}>
                                                <div>
                                                    <img src={maskGroup} />
                                                </div>
                                                <div>
                                                    <p title={el.pickup.name}>{el.pickup.name}</p>
                                                    <span title={el.pickup.address}>{el.pickup.address}</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* =============NEW DESIGN DISTANCE================== */}
                                        <div className={styles.newDestance}>
                                            <div className={styles.selectDiv}>
                                                <select
                                                    className={styles.mapDistance}
                                                    onChange={(e) => {
                                                        const dis = e.target.value;
                                                        if (dis === (el.distance / 1000 / 1.609).toFixed(1)) {
                                                            setDisData(dis + " mi");
                                                        } else {
                                                            setDisData(dis + " km");
                                                        }
                                                    }}
                                                >
                                                    <option value={(el.distance / 1000 / 1.609).toFixed(1)}>Distance ( mi )</option>
                                                    <option value={(el.distance / 1000).toFixed(1)}>Distance ( km )</option>
                                                </select>
                                            </div>
                                            <p>{disData ? disData : (el.distance / 1000 / 1.609).toFixed(1) + " mi"}</p>
                                        </div>
                                        {/* =============NEW DESIGN DISTANCE================== */}
                                    </>
                                )}
                            </div>
                        ))}
                    </>
                    {/* ============================ */}
                    <div className={styles.right}>
                        <CarModelMap />
                    </div>
                </div>
            )}
        </>
    );
}

export default Location;
