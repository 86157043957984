import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CarModel from "../carModel/CarModel";
import Client from "../client/Client";
import DamagesOverview from "../damageOverview/DamagesOverview";
import DamagesImages from "../damagesImages/DamagesImages";
import Documents from "../documents/Documents";
import Notes from "../notes/Notes";
import Photos from "../photos/Photos";
import Signatures from "../signatures/Signatures";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import InspectionNotFound from "../inspectionNotFound/InspectionNotFound";

// =================

// =================

function Inspections() {
    let params = useParams();
    const [isFound, SetIsFound] = useState(true);
    const [allData, setAllData] = useState([]);
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);
    const [date, setDate] = useState();
    const [workspace, setWorkspace] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [notes, setNotes] = useState([]);
    const [signatures, setSignatures] = useState([]);
    const [carModel, setCarModal] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [diagram, setDiagram] = useState([]);
    const [location, setLocation] = useState([]);
    const [locationCenterName, setLocationCenterName] = useState([]);
    const [pickup, setPickup] = useState([]);
    const [delivery, setDelivery] = useState([]);

    const [imgs, setImgs] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [modal, setModal] = useState(false);

    const [damagesData, setDamagesData] = useState([]);
    const [vehicleData2, setVehicleData2] = useState("");
    const [frontSideData, setFrontSideData] = useState([]);
    const [leftSideData, setLeftSideData] = useState([]);
    const [rearSideData, setRearSideData] = useState([]);
    const [rightSideData, setRightSideData] = useState([]);
    const [topSideData, setTopSideData] = useState([]);

    useEffect(() => {
        axios
            .get(`https://api.ryets.com/prod/workspaces/${params.workspaceId}/inspections/${params.inspectionId}`, {
                params: { expand: "workspace,user" },
            })
            .then((res) => {
                setAllData([res.data.data]);
                setData(res.data.data);
                setUser(res.data.data.user);
                setDate(new Date(res.data.data.createdAt));
                setWorkspace(res.data.data.workspace);
                setDocumentsData(res.data.data.documents);
                setVehicleData(res.data.data.vehicle);
                setCarModal([res.data.data.vehicle]);
                setClientData([res.data.data.client]);
                setNotes(res.data.data.notes);
                setSignatures(res.data.data.signatures);
                setDiagram(res.data.data.diagram);
                setLocation([res.data.data.locations]);
                setLocationCenterName(res.data.data.locations);
                setPickup(res.data.data.locations.pickup);
                setDelivery(res.data.data.locations.delivery);
                setImgs(res.data.data.photos);
                setSliderData(res.data.data.photos[0]);

                setDamagesData(res.data.data.diagram);
                setVehicleData2(res.data.data.vehicle.type);

                setFrontSideData(res.data.data.diagram.front);
                setLeftSideData(res.data.data.diagram.left);
                setRearSideData(res.data.data.diagram.back);
                setRightSideData(res.data.data.diagram.right);
                setTopSideData(res.data.data.diagram.roof);
                SetIsFound(true);
            })
            .catch((err) => {
                console.log("something Error");
                SetIsFound(false);
            });
    }, []);

    // ==================

    const handleClick = (index) => {
        const slider = imgs[index];
        setSliderData(slider);
    };
    const toggleModal = () => {
        setModal(!modal);
    };
    const handleClickAndToggleModal = (index) => {
        const slider = imgs[index];
        setSliderData(slider);
        setModal(!modal);
    };
    // ==================
    return (
        <>
            {isFound ? (
                <div>
                    <Navbar workspace={workspace} />
                    <div className="content">
                        <Client user={user} date={date} />
                        <CarModel
                            carModel={carModel}
                            clientData={clientData}
                            diagram={diagram}
                            documentsData={documentsData}
                            allData={allData}
                            data={data}
                            location={location}
                            pickup={pickup}
                            delivery={delivery}
                            locationCenterName={locationCenterName}
                        />
                        <Documents documentsData={documentsData} vehicleData={vehicleData} />
                        <Photos
                            imgs={imgs}
                            sliderData={sliderData}
                            modal={modal}
                            handleClick={handleClick}
                            toggleModal={toggleModal}
                            handleClickAndToggleModal={handleClickAndToggleModal}
                        />
                        <DamagesOverview damagesData={damagesData} vehicleData={vehicleData2} />
                        <DamagesImages
                            damagesData={damagesData}
                            frontSideData={frontSideData}
                            leftSideData={leftSideData}
                            rearSideData={rearSideData}
                            rightSideData={rightSideData}
                            topSideData={topSideData}
                        />
                        <Notes notes={notes} />
                        <Signatures signatures={signatures} />
                    </div>
                    <Footer />
                </div>
            ) : (
                <InspectionNotFound />
            )}
        </>
    );
}

export default Inspections;
