import React from "react";
import styles from "../CarModel.module.css";

function Count({ allData }) {
    return (
        <div>
            {allData.map((el, index) => (
                <div className={styles.count} key={index}>
                    <div>
                        <p>{el.photos ? el.photos.length : 0}</p>
                        <span>Photos</span>
                    </div>
                    <div className={styles.orSymboll}>|</div>
                    {/* ============== */}
                    <div>
                        <p>
                            {el.diagram
                                ? `${el.diagram.left.length +
                                      el.diagram.right.length +
                                      el.diagram.back.length +
                                      el.diagram.roof.length +
                                      el.diagram.front.length}`
                                : 0}
                        </p>
                        <span>Damages</span>
                    </div>
                    <div className={styles.orSymboll}>|</div>
                    {/* ============== */}
                    <div>
                        <p>{el.documents ? el.documents.length : 0}</p>
                        <span>Documents</span>
                    </div>
                    <div className={styles.orSymboll}>|</div>
                    {/* ============== */}
                    <div>
                        <p>{el.notes ? el.notes.length : 0}</p>
                        <span>Notes</span>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Count;
