import React from "react";
import styles from "./DamagesOverview.module.css";

// ====================Sedan========================
import Sedan_Left from "../../images/SedanLeft.png";
import Sedan_Rear from "../../images/SedanRear.png";
import Sedan_Front from "../../images/SedanFront.png";
import Sedan_Right from "../../images/SedanRight.png";
import Sedan_Top from "../../images/SedanTop.png";

// ======================SUV=======================
import SUV_Front from "../../images/SUV_Front.png";
import SUV_Left from "../../images/SUV_Left.png";
import SUV_Rear from "../../images/SUV_Rear.png";
import SUV_Right from "../../images/SUV_Right.png";
import SUV_Top from "../../images/SUV_Top.png";

// ======================Cargo Van=======================
import Cargovan_Front from "../../images/Cargovan_Front.png";
import Cargovan_Left from "../../images/Cargovan_Left.png";
import Cargovan_Rear from "../../images/Cargovan_Rear.png";
import Cargovan_Right from "../../images/Cargovan_Right.png";
import Cargovan_Top from "../../images/Cargovan_Top.png";

// ======================Coupe=======================
import Coupe_Front from "../../images/Coupe_Front.png";
import Coupe_Left from "../../images/Coupe_Left.png";
import Coupe_Rear from "../../images/Coupe_Rear.png";
import Coupe_Right from "../../images/Coupe_Right.png";
import Coupe_Top from "../../images/Coupe_Top.png";

// ======================Hatchback=======================
import Hatchback_Front from "../../images/Hatchback_Front.png";
import Hatchback_Left from "../../images/Hatchback_Left.png";
import Hatchback_Rear from "../../images/Hatchback_Rear.png";
import Hatchback_Right from "../../images/Hatchback_Right.png";
import Hatchback_Top from "../../images/Hatchback_Top.png";

// ======================Mini Van=======================
import Minivan_Front from "../../images/Minivan_Front.png";
import Minivan_Left from "../../images/Minivan_Left.png";
import Minivan_Rear from "../../images/Minivan_Rear.png";
import Minivan_Right from "../../images/Minivan_Right.png";
import Minivan_Top from "../../images/Minivan_Top.png";

// ======================Pickup=======================
import Pickup_Front from "../../images/Pickup_Front.png";
import Pickup_Left from "../../images/Pickup_Left.png";
import Pickup_Rear from "../../images/Pickup_Rear.png";
import Pickup_Right from "../../images/Pickup_Right.png";
import Pickup_Top from "../../images/Pickup_Top.png";

// ==================Damages Icons======================
import Burned from "../../images/Burned@2x.png";
import Crack from "../../images/Crack@2x.png";
import Cut from "../../images/Cut@2x.png";
import Dent from "../../images/Dent@2x.png";
import Flooded from "../../images/Flooded@2x.png";
import FrontDamage from "../../images/Front Damage@2x.png";
import Hail from "../../images/Hail@2x.png";
import Overturned from "../../images/Overturned@2x.png";
import RearDamage from "../../images/Rear Damage@2x.png";
import Scratch from "../../images/Scratch@2x.png";
import SideDamage from "../../images/Side Damage@2x.png";
// ===========================================================
// ===========================================================

function DamagesOverview({ damagesData, vehicleData }) {
    const types = [
        { type: "crack", img: Crack },
        { type: "scratch", img: Scratch },
        { type: "dent", img: Dent },
        { type: "cut", img: Cut },
        { type: "frontDamage", img: FrontDamage },
        { type: "hail", img: Hail },
        { type: "overturned", img: Overturned },
        { type: "rearDamage", img: RearDamage },
        { type: "sideDamage", img: SideDamage },
        { type: "burned", img: Burned },
        { type: "flooded", img: Flooded },
    ];

    return (
        <>
            {damagesData && (
                <div>
                    <h2>Damages Overview</h2>

                    <div className={styles.images}>
                        <div
                            className={styles.rightSide}
                            style={
                                vehicleData === "SUV"
                                    ? { backgroundImage: `url(${SUV_Right})` }
                                    : vehicleData === "Sedan"
                                    ? { backgroundImage: `url(${Sedan_Right})` }
                                    : vehicleData === "Cargo Van"
                                    ? {
                                          backgroundImage: `url(${Cargovan_Right})`,
                                      }
                                    : vehicleData === "Coupe"
                                    ? { backgroundImage: `url(${Coupe_Right})` }
                                    : vehicleData === "Hatchback"
                                    ? {
                                          backgroundImage: `url(${Hatchback_Right})`,
                                      }
                                    : vehicleData === "Mini Van"
                                    ? {
                                          backgroundImage: `url(${Minivan_Right})`,
                                      }
                                    : vehicleData === "Pickup"
                                    ? {
                                          backgroundImage: `url(${Pickup_Right})`,
                                      }
                                    : {}
                            }
                        >
                            {damagesData.right && damagesData.right.length != 0 && (
                                <>
                                    {damagesData.right.map((damage) => (
                                        <div
                                            key={damage.remoteID}
                                            className={damage.isHandled ? styles.damX2 : styles.damX}
                                            style={{
                                                top: `${(1 - damage.projectedWorld.y) * document.querySelector(`.${styles.rightSide}`).offsetHeight -
                                                    10}px`,
                                                left: `${(1 - damage.projectedWorld.x) * document.querySelector(`.${styles.rightSide}`).offsetWidth -
                                                    10}px`,
                                            }}
                                        >
                                            {damage.type === "crack" && <img src={Crack} />}
                                            {damage.type === "scratch" && <img src={Scratch} />}
                                            {damage.type === "dent" && <img src={Dent} />}
                                            {damage.type === "cut" && <img src={Cut} />}
                                            {damage.type === "frontDamage" && <img src={FrontDamage} />}
                                            {damage.type === "hail" && <img src={Hail} />}
                                            {damage.type === "overturned" && <img src={Overturned} />}
                                            {damage.type === "rearDamage" && <img src={RearDamage} />}
                                            {damage.type === "sideDamage" && <img src={SideDamage} />}
                                            {damage.type === "burned" && <img src={Burned} />}
                                            {damage.type === "flooded" && <img src={Flooded} />}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {/* ======================== */}
                        {/* <div className={styles.midelDiv}> */}
                        <div
                            className={styles.frontSide}
                            style={
                                vehicleData === "Sedan"
                                    ? { backgroundImage: `url(${Sedan_Front})` }
                                    : vehicleData === "SUV"
                                    ? { backgroundImage: `url(${SUV_Front})` }
                                    : vehicleData === "Cargo Van"
                                    ? {
                                          backgroundImage: `url(${Cargovan_Front})`,
                                      }
                                    : vehicleData === "Coupe"
                                    ? { backgroundImage: `url(${Coupe_Front})` }
                                    : vehicleData === "Hatchback"
                                    ? {
                                          backgroundImage: `url(${Hatchback_Front})`,
                                      }
                                    : vehicleData === "Mini Van"
                                    ? {
                                          backgroundImage: `url(${Minivan_Front})`,
                                      }
                                    : vehicleData === "Pickup"
                                    ? {
                                          backgroundImage: `url(${Pickup_Front})`,
                                      }
                                    : {}
                            }
                        >
                            {damagesData.front && damagesData.front.length != 0 && (
                                <>
                                    {damagesData.front.map((damage) => (
                                        <div
                                            key={damage.remoteID}
                                            className={damage.isHandled ? styles.damX2 : styles.damX}
                                            style={{
                                                top: `${damage.projectedWorld.x * document.querySelector(`.${styles.frontSide}`).offsetHeight -
                                                    10}px`,
                                                left: `${(1 - damage.projectedWorld.y) * document.querySelector(`.${styles.frontSide}`).offsetWidth -
                                                    10}px`,
                                            }}
                                        >
                                            {damage.type === "crack" && <img src={Crack} />}
                                            {damage.type === "scratch" && <img src={Scratch} />}
                                            {damage.type === "dent" && <img src={Dent} />}
                                            {damage.type === "cut" && <img src={Cut} />}
                                            {damage.type === "frontDamage" && <img src={FrontDamage} />}
                                            {damage.type === "hail" && <img src={Hail} />}
                                            {damage.type === "overturned" && <img src={Overturned} />}
                                            {damage.type === "rearDamage" && <img src={RearDamage} />}
                                            {damage.type === "sideDamage" && <img src={SideDamage} />}
                                            {damage.type === "burned" && <img src={Burned} />}
                                            {damage.type === "flooded" && <img src={Flooded} />}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div
                            className={styles.topSide}
                            style={
                                vehicleData === "Sedan"
                                    ? { backgroundImage: `url(${Sedan_Top})` }
                                    : vehicleData === "SUV"
                                    ? { backgroundImage: `url(${SUV_Top})` }
                                    : vehicleData === "Cargo Van"
                                    ? {
                                          backgroundImage: `url(${Cargovan_Top})`,
                                      }
                                    : vehicleData === "Coupe"
                                    ? { backgroundImage: `url(${Coupe_Top})` }
                                    : vehicleData === "Hatchback"
                                    ? {
                                          backgroundImage: `url(${Hatchback_Top})`,
                                      }
                                    : vehicleData === "Mini Van"
                                    ? { backgroundImage: `url(${Minivan_Top})` }
                                    : vehicleData === "Pickup"
                                    ? { backgroundImage: `url(${Pickup_Top})` }
                                    : {}
                            }
                        >
                            {damagesData.roof && damagesData.roof.length != 0 && (
                                <>
                                    {damagesData.roof.map((damage) => (
                                        <div
                                            key={damage.remoteID}
                                            className={damage.isHandled ? styles.damX2 : styles.damX}
                                            style={{
                                                bottom: `${damage.projectedWorld.x * document.querySelector(`.${styles.topSide}`).offsetHeight -
                                                    10}px`,
                                                left: `${damage.projectedWorld.y * document.querySelector(`.${styles.topSide}`).offsetWidth - 10}px`,
                                            }}
                                        >
                                            {damage.type === "crack" && <img src={Crack} />}
                                            {damage.type === "scratch" && <img src={Scratch} />}
                                            {damage.type === "dent" && <img src={Dent} />}
                                            {damage.type === "cut" && <img src={Cut} />}
                                            {damage.type === "frontDamage" && <img src={FrontDamage} />}
                                            {damage.type === "hail" && <img src={Hail} />}
                                            {damage.type === "overturned" && <img src={Overturned} />}
                                            {damage.type === "rearDamage" && <img src={RearDamage} />}
                                            {damage.type === "sideDamage" && <img src={SideDamage} />}
                                            {damage.type === "burned" && <img src={Burned} />}
                                            {damage.type === "flooded" && <img src={Flooded} />}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div
                            className={styles.reerSide}
                            style={
                                vehicleData === "Sedan"
                                    ? { backgroundImage: `url(${Sedan_Rear})` }
                                    : vehicleData === "SUV"
                                    ? { backgroundImage: `url(${SUV_Rear})` }
                                    : vehicleData === "Cargo Van"
                                    ? {
                                          backgroundImage: `url(${Cargovan_Rear})`,
                                      }
                                    : vehicleData === "Coupe"
                                    ? { backgroundImage: `url(${Coupe_Rear})` }
                                    : vehicleData === "Hatchback"
                                    ? {
                                          backgroundImage: `url(${Hatchback_Rear})`,
                                      }
                                    : vehicleData === "Mini Van"
                                    ? {
                                          backgroundImage: `url(${Minivan_Rear})`,
                                      }
                                    : vehicleData === "Pickup"
                                    ? { backgroundImage: `url(${Pickup_Rear})` }
                                    : {}
                            }
                        >
                            {damagesData.back && damagesData.back.length != 0 && (
                                <>
                                    {damagesData.back.map((damage) => (
                                        <div
                                            key={damage.remoteID}
                                            className={damage.isHandled ? styles.damX2 : styles.damX}
                                            style={{
                                                top: `${(1 - damage.projectedWorld.x) * document.querySelector(`.${styles.reerSide}`).offsetHeight -
                                                    10}px`,
                                                left: `${damage.projectedWorld.y * document.querySelector(`.${styles.reerSide}`).offsetWidth - 10}px`,
                                            }}
                                        >
                                            {damage.type === "crack" && <img src={Crack} />}
                                            {damage.type === "scratch" && <img src={Scratch} />}
                                            {damage.type === "dent" && <img src={Dent} />}
                                            {damage.type === "cut" && <img src={Cut} />}
                                            {damage.type === "frontDamage" && <img src={FrontDamage} />}
                                            {damage.type === "hail" && <img src={Hail} />}
                                            {damage.type === "overturned" && <img src={Overturned} />}
                                            {damage.type === "rearDamage" && <img src={RearDamage} />}
                                            {damage.type === "sideDamage" && <img src={SideDamage} />}
                                            {damage.type === "burned" && <img src={Burned} />}
                                            {damage.type === "flooded" && <img src={Flooded} />}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {/* </div> */}
                        {/* ======================== */}

                        <div
                            className={styles.leftSide}
                            style={
                                vehicleData === "Sedan"
                                    ? { backgroundImage: `url(${Sedan_Left})` }
                                    : vehicleData === "SUV"
                                    ? { backgroundImage: `url(${SUV_Left})` }
                                    : vehicleData === "Cargo Van"
                                    ? {
                                          backgroundImage: `url(${Cargovan_Left})`,
                                      }
                                    : vehicleData === "Coupe"
                                    ? { backgroundImage: `url(${Coupe_Left})` }
                                    : vehicleData === "Hatchback"
                                    ? {
                                          backgroundImage: `url(${Hatchback_Left})`,
                                      }
                                    : vehicleData === "Mini Van"
                                    ? {
                                          backgroundImage: `url(${Minivan_Left})`,
                                      }
                                    : vehicleData === "Pickup"
                                    ? { backgroundImage: `url(${Pickup_Left})` }
                                    : {}
                            }
                        >
                            {damagesData.left && damagesData.left.length != 0 && (
                                <>
                                    {damagesData.left.map((damage) => (
                                        <div
                                            key={damage.remoteID}
                                            className={damage.isHandled ? styles.damX2 : styles.damX}
                                            style={{
                                                top: `${damage.projectedWorld.y * document.querySelector(`.${styles.leftSide}`).offsetHeight - 10}px`,
                                                left: `${damage.projectedWorld.x * document.querySelector(`.${styles.leftSide}`).offsetWidth - 10}px`,
                                            }}
                                        >
                                            {damage.type === "crack" && <img src={Crack} />}
                                            {damage.type === "scratch" && <img src={Scratch} />}
                                            {damage.type === "dent" && <img src={Dent} />}
                                            {damage.type === "cut" && <img src={Cut} />}
                                            {damage.type === "frontDamage" && <img src={FrontDamage} />}
                                            {damage.type === "hail" && <img src={Hail} />}
                                            {damage.type === "overturned" && <img src={Overturned} />}
                                            {damage.type === "rearDamage" && <img src={RearDamage} />}
                                            {damage.type === "sideDamage" && <img src={SideDamage} />}
                                            {damage.type === "burned" && <img src={Burned} />}
                                            {damage.type === "flooded" && <img src={Flooded} />}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DamagesOverview;

// x: 0.5635014176368713 * 513, y: 0.4181383550167084*162
