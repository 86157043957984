import React from "react";
import styles from "../CarModel.module.css";

function ClientData({ clientData, data }) {
    return (
        <div>
            {data.client ? (
                <>
                    {clientData.map((el, index) => (
                        <div key={index}>
                            <div className={styles.user}>
                                <h4>{el.name ? el.name : "---"}</h4>
                                <p>{el.company ? el.company : "---"}</p>
                            </div>
                            <p>{el.address.line1 ? el.address.line1 : "---"}</p>
                            <p>{el.address.line2 ? el.address.line2 : ""}</p>
                            <p>{el.address.state ? el.address.state : "---"}</p>
                            <p>{el.address.country ? el.address.country : "---"}</p>
                        </div>
                    ))}
                </>
            ) : (
                <div>
                    <div className={styles.nouser}>
                        <h4
                            style={{
                                margin: "0",
                                color: "#888",
                                fontWeight: "400",
                            }}
                        >
                            No Client Added
                        </h4>
                        {/* <p>No Commpany Added</p> */}
                    </div>
                    {/* <p>No Address Line Added</p>
                    <p>No Address State Added</p>
                    <p>No Address Countery Added</p> */}
                </div>
            )}
        </div>
    );
}

export default ClientData;
