import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Inspections from "./components/inspections/Inspections";

import "mapbox-gl/dist/mapbox-gl.css";
import InspectionNotFound from "./components/inspectionNotFound/InspectionNotFound";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<InspectionNotFound />} />
                <Route
                    exact
                    path="workspaces/:workspaceId/inspections/:inspectionId"
                    element={
                        <div className="App">
                            <Inspections />
                        </div>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
