import React, { useState } from "react";
import styles from "./Navbar.module.css";
import logoImg from "../../images/MiddleBox Logo.png";
import { FiLink } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toastrImg from "../../images/toastr.png";

// import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

const copyUrl = window.location.href;
function Navbar({ workspace }) {
    const [toastr, setToastr] = useState(false);
    const tefa = () => {
        clearTimeout();

        setToastr(true);
        setTimeout(() => {
            setToastr(false);
        }, 2000);
    };
    return (
        <div className={styles.nav}>
            <div className="container">
                <div className={styles.navContent}>
                    <div className={styles.left}>
                        <div className={styles.logo}>
                            <img src={workspace.logo ? workspace.logo : logoImg} />
                        </div>
                        <div className={styles.companyName}>
                            <p>{workspace.name ? workspace.name : "MiddleDriver, Inc"}.</p>
                        </div>
                    </div>
                    {/* ====================================== */}
                    <div className={styles.right}>
                        {/* <a href={report} target="_blank">
                            <BsDownload className={styles.icon} />
                        </a> */}

                        <CopyToClipboard text={copyUrl}>
                            <FiLink onClick={tefa} className={styles.icon} />
                        </CopyToClipboard>
                    </div>
                </div>
            </div>

            {toastr ? (
                <div className={styles.toastrContainer} style={{ transform: "translateY(0px)" }}>
                    <div className={styles.toastr}>
                        <div className={styles.toastrBody}>
                            <img src={toastrImg} />
                            <p>Link Copied</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.toastrContainer} style={{ transform: "translateY(-200px)" }}>
                    <div className={styles.toastr}>
                        <div className={styles.toastrBody}>
                            <img src={toastrImg} />
                            <p>Link Copied</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;
