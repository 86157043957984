import React, { useState } from "react";
import styles from "../DamagesImages.module.css";
import { CgClose } from "react-icons/cg";
import Burned from "../../../images/Burned@2x.png";
import Crack from "../../../images/Crack@2x.png";
import Cut from "../../../images/Cut@2x.png";
import Dent from "../../../images/Dent@2x.png";
import Flooded from "../../../images/Flooded@2x.png";
import FrontDamage from "../../../images/Front Damage@2x.png";
import Hail from "../../../images/Hail@2x.png";
import Overturned from "../../../images/Overturned@2x.png";
import RearDamage from "../../../images/Rear Damage@2x.png";
import Scratch from "../../../images/Scratch@2x.png";
import SideDamage from "../../../images/Side Damage@2x.png";

// ==========================================
const formatDamages = (side) => {
    const damages = [];

    side.forEach((part) => {
        const subParentName = part.subParentName.replaceAll("_", " ");
        const damage = {
            type: part.type,
            photoURL: part.photoURL,
            isHandled: part.isHandled,
            hasImage: part.hasImage,
            subParentName: part.subParentName,
        };

        if (!damages[subParentName]) {
            damages[subParentName] = [];
            damages[subParentName].push(damage);
        } else {
            damages[subParentName].push(damage);
        }
    });

    return damages;
};
// ==========================================
function FrontSide({ frontSideData }) {
    // let params = useParams();
    // =============================================
    // Use State
    // =============================================
    const [modal, setModal] = useState(false);
    const [temoImgSrc, setTempImgSrc] = useState("");

    // =============================================
    // Functions
    // =============================================
    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModal(true);
    };
    // ===================================================

    const frontSideDamages = formatDamages(frontSideData);

    return (
        <>
            {frontSideData.length > 0 && (
                <div className={styles.leftSide}>
                    <h3>Front Side</h3>
                    {Object.entries(frontSideDamages).map(([key, values], index) => {
                        return (
                            <div key={index}>
                                <div>
                                    {!frontSideData.hasImage && <h4>{key}</h4>}

                                    <div className={styles.frontSideImages}>
                                        {values.map((value) => (
                                            <>
                                                {/* {value.hasImage && ( */}
                                                <div className={styles.item}>
                                                    <img src={value.photoURL} onClick={() => getImg(value.photoURL)} />
                                                    <div className={styles.imgFooter}>
                                                        <div className={styles.left}>
                                                            {value.type === "crack" && <img src={Crack} />}
                                                            {value.type === "scratch" && <img src={Scratch} />}
                                                            {value.type === "dent" && <img src={Dent} />}
                                                            {value.type === "cut" && <img src={Cut} />}
                                                            {value.type === "frontDamage" && <img src={FrontDamage} />}
                                                            {value.type === "hail" && <img src={Hail} />}
                                                            {value.type === "overturned" && <img src={Overturned} />}
                                                            {value.type === "rearDamage" && <img src={RearDamage} />}
                                                            {value.type === "sideDamage" && <img src={SideDamage} />}
                                                            {value.type === "burned" && <img src={Burned} />}
                                                            {value.type === "flooded" && <img src={Flooded} />}
                                                            <p>{value.type}</p>
                                                        </div>
                                                        <div className={styles.right}>
                                                            <p>{value.isHandled ? "Handling" : ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* )} */}
                                            </>
                                        ))}
                                    </div>
                                    <div className={modal ? `${styles.modal} ${styles.open}` : `${styles.modal}`}>
                                        <img src={temoImgSrc} />
                                        <CgClose className={styles.closeIcon} onClick={() => setModal(false)} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
        // <div>
        //     {frontSideData.length > 0 && (
        //         <div className={styles.frontSide}>
        //             <h3>Front Side</h3>
        //             {/* <h4>Front Bumper</h4> */}
        //             {/* ================================== */}
        //             <div className={modal ? `${styles.modal} ${styles.open}` : `${styles.modal}`}>
        //                 <img src={temoImgSrc} />
        //                 <CgClose className={styles.closeIcon} onClick={() => setModal(false)} />
        //             </div>
        //             <div className={styles.frontSideImages}>
        //                 {frontSideData.map((item, index) => (
        //                     <>
        //                         {item.hasImage && (
        //                             <div className={styles.item} key={index}>
        //                                 <img src={item.photoURL} onClick={() => getImg(item.photoURL)} />

        //                                 <div className={styles.imgFooter}>
        //                                     <div className={styles.left}>
        //                                         {item.type === "crack" && <img src={Crack} />}
        //                                         {item.type === "scratch" && <img src={Scratch} />}
        //                                         {item.type === "dent" && <img src={Dent} />}
        //                                         {item.type === "cut" && <img src={Cut} />}
        //                                         {item.type === "frontDamage" && <img src={FrontDamage} />}
        //                                         {item.type === "hail" && <img src={Hail} />}
        //                                         {item.type === "overturned" && <img src={Overturned} />}
        //                                         {item.type === "rearDamage" && <img src={RearDamage} />}
        //                                         {item.type === "sideDamage" && <img src={SideDamage} />}
        //                                         {item.type === "burned" && <img src={Burned} />}
        //                                         {item.type === "flooded" && <img src={Flooded} />}
        //                                         {/* ============================== */}
        //                                         <p>{item.type}</p>
        //                                     </div>
        //                                     {/* ================================== */}
        //                                     <div className={styles.right}>
        //                                         <p>{item.isHandled ? "Handling" : ""}</p>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         )}
        //                     </>
        //                 ))}
        //                 {/* ===================================== */}
        //                 {/* ================================== */}
        //             </div>
        //             {/* ===================================== */}
        //         </div>
        //     )}
        // </div>
    );
}

export default FrontSide;
