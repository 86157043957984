import React from "react";
import styles from "./Client.module.css";
import clientImg from "../../images/User.png";

function Client({ user, date }) {
    const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    const inspectionDate = Intl.DateTimeFormat("en-US", options).format(date);

    return (
        <div className={styles.client}>
            <div className={styles.client_left}>
                <div className={styles.clientImg}>
                    <img src={user.photo ? user.photo : clientImg} />
                </div>
                <p>
                    {user.firstName} {user.lastName}
                </p>
            </div>
            <div className={styles.client_right}>
                <p>
                    Inspected at
                    <span> {inspectionDate} </span>
                </p>
            </div>
        </div>
    );
}

export default Client;
