import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import markerImage from "../../images/Group 17384.png";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
mapboxgl.accessToken =
    "pk.eyJ1IjoiYWhtZWQtYWJkZWxsYXRpZiIsImEiOiJjbDdoejEzdjIwajZkM25tbTZjcHF0MmVsIn0.Ku6SZ1XCxepFwrKlKF9Uhg";

function CarModelMap() {
    let params = useParams();
    let map;
    let [lng, setLng] = useState(null);
    let [lat, setLat] = useState(null);
    let [lng2, setLng2] = useState(null);
    let [lat2, setLat2] = useState(null);

    useEffect(() => {
        axios
            .get(
                `https://api.ryets.com/prod/workspaces/${params.workspaceId}/inspections/${params.inspectionId}`,
                {
                    params: { expand: "workspace" },
                }
            )
            .then((res) => {
                setLng(res.data.data.locations.pickup.lon);
                setLat(res.data.data.locations.pickup.lat);
                setLng2(res.data.data.locations.delivery.lon);
                setLat2(res.data.data.locations.delivery.lat);
            })
            .catch((err) => {
                console.log("something Error");
            });
        map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/light-v10",
            center: [lng, lat],
            zoom: 7,
        });

        map.on("load", () => {
            const directions = new MapboxDirections({
                styles: [
                    {
                        id: "directions-route-line-casing",
                        type: "line",
                        source: "directions",
                        layout: {
                            "line-cap": "round",
                            "line-join": "round",
                        },
                        paint: {
                            "line-color": "#000",
                            "line-width": 0,
                        },
                        filter: [
                            "all",
                            ["in", "$type", "LineString"],
                            ["in", "route", "selected"],
                        ],
                    },
                    {
                        id: "directions-route-line",
                        type: "line",
                        source: "directions",
                        layout: {
                            "line-cap": "butt",
                            "line-join": "round",
                        },
                        paint: {
                            "line-color": {
                                property: "congestion",
                                type: "categorical",
                                default: "#0078d4",
                                stops: [
                                    ["unknown", "#4882c5"],
                                    ["low", "#4882c5"],
                                    ["moderate", "#f09a46"],
                                    ["heavy", "#e34341"],
                                    ["severe", "#8b2342"],
                                ],
                            },
                            "line-width": 3,
                        },
                        filter: [
                            "all",
                            ["in", "$type", "LineString"],
                            ["in", "route", "selected"],
                        ],
                    },

                    {
                        id: "directions-origin-point",
                        type: "circle",
                        source: "directions",
                        paint: {
                            "circle-radius": 0,
                            "circle-color": "#0078d4",
                        },
                        filter: [
                            "all",
                            ["in", "$type", "Point"],
                            ["in", "marker-symbol", "A"],
                        ],
                    },
                    {
                        id: "directions-origin-label",
                        type: "symbol",
                        source: "directions",
                        layout: {
                            "text-field": "",
                            "text-font": [
                                "Open Sans Bold",
                                "Arial Unicode MS Bold",
                            ],
                            "text-size": 12,
                        },
                        paint: {
                            "text-color": "#fff",
                        },
                        filter: [
                            "all",
                            ["in", "$type", "Point"],
                            ["in", "marker-symbol", "A"],
                        ],
                    },
                    {
                        id: "directions-destination-point",
                        type: "circle",
                        source: "directions",
                        paint: {
                            "circle-radius": 0,
                            "circle-color": "#0078d4",
                        },
                        filter: [
                            "all",
                            ["in", "$type", "Point"],
                            ["in", "marker-symbol", "B"],
                        ],
                    },
                    {
                        id: "directions-destination-label",
                        type: "symbol",
                        source: "directions",
                        layout: {
                            "text-field": "",
                            "text-font": [
                                "Open Sans Bold",
                                "Arial Unicode MS Bold",
                            ],
                            "text-size": 12,
                        },
                        paint: {
                            "text-color": "#fff",
                        },
                        filter: [
                            "all",
                            ["in", "$type", "Point"],
                            ["in", "marker-symbol", "B"],
                        ],
                    },
                ],
                accessToken: mapboxgl.accessToken,
                unit: "metric",
                // alternatives: true,
                controls: {
                    inputs: false,
                    instructions: false,
                    profileSwitcher: false,
                },
                routePadding: 50,
                interactive: false,
                profile: "mapbox/driving",
            });
            map.addControl(directions);
            new mapboxgl.Marker({ color: "#0078d4" })
                .setLngLat([lng, lat])
                .addTo(map);
            new mapboxgl.Marker({ color: "#0078d4" })
                .setLngLat([lng2, lat2])
                .addTo(map);

            directions.setOrigin([`${lng}`, `${lat}`]);
            directions.setDestination([`${lng2}`, `${lat2}`]);
        });
    }, [lng, lat, lng2, lat2]);
    return (
        <div>
            <div id="map" style={{ height: "335px" }}></div>
        </div>
    );
}

export default CarModelMap;
