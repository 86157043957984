import React from "react";
import styles from "./DamagesImages.module.css";
import FrontSide from "./frontSide/FrontSide";
import LeftSide from "./leftSide/LeftSide";
import RightSide from "./rightSide/RightSide";
import TopSide from "./topSide/TopSide";
import RearSide from "./rearSide/RearSide";

function DamagesImages({ damagesData, frontSideData, leftSideData, rearSideData, rightSideData, topSideData }) {
    const damages = {
        borderBottom: "2px solid #e2e2e2",
        paddingBottom: "60px",
        marginBottom: "60px",
    };
    const damagesNone = {
        borderBottom: "none",
        paddingBottom: "0px",
        marginBottom: "0px",
    };

    return (
        <div className={styles.damages} style={damagesData ? damages : damagesNone}>
            {/* ============Front Side============ */}
            <FrontSide frontSideData={frontSideData} />
            {/* ================Left Side================= */}
            <LeftSide leftSideData={leftSideData} />
            {/* ========================================== */}
            {/* ================Rear Side================= */}
            <RearSide rearSideData={rearSideData} />
            {/* ========================================== */}
            {/* ================Right Side================= */}
            <RightSide rightSideData={rightSideData} />
            {/* ========================================== */}
            {/* ================Top Side================= */}
            <TopSide topSideData={topSideData} />
            {/* ========================================== */}
        </div>
    );
}

export default DamagesImages;
