import React from "react";
import styles from "./Notes.module.css";

function Notes({ notes }) {
    return (
        <>
            {notes.length > 0 && (
                <div className={styles.notes}>
                    <h2 className={styles.notes_title}>Notes</h2>
                    {notes.map((el) => (
                        <div className={styles.notes_msg} key={el.id}>
                            <p>{el.body}</p>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default Notes;
