import React from "react";
import styles from "./Signatures.module.css";

function Signatures({ signatures }) {
    return (
        <>
            {signatures.length != 0 && (
                <div className={styles.Signatures}>
                    <h2 className={styles.signatures_title}>
                        Signatures
                        <sup>
                            <small>1</small>
                        </sup>
                    </h2>
                    {/* ================================================ */}

                    <div className={styles.signatures_content}>
                        {signatures.map((el) => (
                            <div className={styles.signatures_content_card} key={el.url}>
                                <img src={el.url} alt="" />
                                <p>{el.fullName}</p>
                                <span>{el.role}</span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.orederListDiv}>
                        <ol>
                            <li>
                                By signing, you agree to the terms and conditions of this car inspection report. Signing is acceptance. Also, You
                                agree to accept all of the terms and conditions of this agreement and understand that Ryets is relying upon the
                                Inspector stated acceptance of such terms and conditions.
                            </li>
                            <ol>
                                <li>
                                    This Signature is only for this inspection delivery; it doesn’t apply to any signature but the one shown to you.
                                </li>
                                <li>The Inspection Report, in a PDF file, will be sent to you by email.</li>
                                <li>Ryets can’t do anything about the behave of the signature owner.</li>
                            </ol>
                        </ol>
                    </div>

                    {/* ================================================ */}
                </div>
            )}
        </>
    );
}

export default Signatures;
