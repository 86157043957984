import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./centerName.module.css";

function CenterName({ locationCenterName }) {
    // let params = useParams();
    // // const [location, setLocation] = useState([]);
    // useEffect(() => {
    //     axios
    //         .get(`https://api.ryets.com/prod/workspaces/${params.workspaceId}/inspections/${params.inspectionId}`, {
    //             params: { expand: "workspace" },
    //         })
    //         .then((res) => {
    //             setLocation(res.data.data.locations);
    //         })
    //         .catch((err) => {
    //             console.log("something Error");
    //         });
    // }, []);

    return (
        <>
            {(locationCenterName.centerName || locationCenterName.area || locationCenterName.number) && (
                <div className={styles.allLocat}>
                    <div className={styles.centerName}>
                        <p>Center Name</p>
                        <h3>{locationCenterName.centerName ? locationCenterName.centerName : "---"}</h3>
                    </div>
                    <div className={styles.area}>
                        <p>Area</p>
                        <h3>{locationCenterName.area ? locationCenterName.area : "---"}</h3>
                    </div>
                    <div className={styles.number}>
                        <p>Location#</p>
                        <h3>{locationCenterName.number ? locationCenterName.number : "---"}</h3>
                    </div>
                </div>
            )}
        </>
    );
}

export default CenterName;
