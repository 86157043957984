import React from "react";
import styles from "./Documents.module.css";
import { BsDownload } from "react-icons/bs";
import fileImg1 from "../../images/Group 17401.png";
import fileImg2 from "../../images/Group 17407.png";
import fileImg3 from "../../images/Group 17408.png";
import fileImg4 from "../../images/Group 173432.png";
import fileImg5 from "../../images/Group 174012.png";
import fileImg6 from "../../images/Path 19839.png";
import fileImg7 from "../../images/Path 19946.png";
import fileImg8 from "../../images/Group 17343.png";

function Documents({ documentsData, vehicleData }) {
    return (
        <>
            {documentsData.length > 0 && (
                <div className={styles.documents}>
                    <h2>Documents</h2>
                    <div className={styles.allDocuments}>
                        {documentsData.map((doc) => (
                            <div className={styles.doc} key={doc.id}>
                                <h3>{`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`}</h3>
                                <div className={styles.docData}>
                                    <div className={styles.left}>
                                        {/* =========================== */}
                                        {doc.type === "Dock Receipt" && (
                                            <>
                                                <img src={fileImg1} />
                                                <p style={{ color: "#0078D4" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Title File" && (
                                            <>
                                                <img src={fileImg2} />
                                                <p style={{ color: "#0B934A" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "C350 Wheels Modifications" && (
                                            <>
                                                <img src={fileImg3} />
                                                <p style={{ color: "#C9AC5E" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Port Stamp Dock Receipt" && (
                                            <>
                                                <img src={fileImg4} />
                                                <p style={{ color: "#C62619" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Delivery Receipt" && (
                                            <>
                                                <img src={fileImg8} />
                                                <p style={{ color: "#007F89" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Pickup Receipt" && (
                                            <>
                                                <img src={fileImg5} />
                                                <p style={{ color: "#4F4F4F" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Claim Form" && (
                                            <>
                                                <img
                                                    src={fileImg6}
                                                    style={{
                                                        width: "16px",
                                                        height: "19px",
                                                    }}
                                                />
                                                <p style={{ color: "#7F00FF" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Repair Estimation" && (
                                            <>
                                                <img
                                                    src={fileImg7}
                                                    style={{
                                                        width: "16px",
                                                        height: "19px",
                                                    }}
                                                />
                                                <p style={{ color: "#265A60" }}>{doc.type}</p>
                                            </>
                                        )}
                                        {/* ==================== */}
                                        {doc.type === "Others" && (
                                            <>
                                                <img src={fileImg3} />
                                                {/* <p style={{ color: "#C9AC5E" }}>{doc.type}</p> */}
                                                <p style={{ color: "#C9AC5E" }}>{doc.title}</p>
                                            </>
                                        )}
                                        {/* ==================== */}

                                        {/* =========================== */}
                                    </div>
                                    <div className={styles.right}>
                                        <a href={doc.url} target="_blank">
                                            <BsDownload />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default Documents;
