import React from "react";
import styles from "./Footer.module.css";
import inspectImg from "../../images/Union 1.png";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Footer() {
    return (
        <div className={styles.footer}>
            <div className="container">
                <div className={styles.footerContent}>
                    <div className={styles.left}>
                        <Link to={"//www.ryets.com"}>
                            <img src={inspectImg} />
                        </Link>
                        <p>
                            Powered by <span>RYETS</span>
                        </p>
                    </div>
                    <div className={styles.right}>
                        <p>© Copyright 2022 Ryets, Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
